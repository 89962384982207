$c-form-part-max-width-lg: 100%;
$c-form-part-max-width-md: 100%;

@import '@pushdr/stylekit/scss/stylekit.scss';

.triage-legend {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 2px;
  text-align: center;
  color: $textColor-bluegrey-700;
  margin-bottom: 16px;
}

.c-form-part.child-registration {
  max-width: 362px;

  @include breakpoint(lg) {
    max-width: 391px;
  }
}

// modal overrides
.c-modal {
  .c-modal__wrap {
    h2 {
      margin-bottom: 20px;
    }
  }
}

// utility classes

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-justify-content-center {
  justify-content: center;
}

.u-w100 {
  width: 100%;
}

.u-flex-column {
  flex-direction: column;
}

.u-bg-transparent {
  background: transparent !important;
}

.u-bg-slanted {
  -webkit-clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 77% 0, 100% 100%, 0% 100%);
  width: 100%;
  background-size: cover;

  @media (max-width: 600px) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 79%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 79%, 0% 100%);
    height: 240px;
    width: 100%;
  }
}

.u-bg-pos-center {
  background-position: center;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

// overrides
$listview-edit-icon-size: 20px;

.c-btn {
  max-width: 100%;
  overflow: hidden;

  .c-btn--quaternary {
    color: white;
    border: 1px solid white;
  }

  &.c-btn--radio {
    text-align: left;
    display: inline-flex;
    align-items: center;
    background: #fff;
    border: 1px solid grey;
    color: grey;
    transition: all 0.2s ease-out;

    &:before {
      content: '';
      display: inline-block;
      height: 20px;
      width: 20px;
      background-color: white;
      border-radius: 100%;
      border: 1px solid grey;
      margin-right: 8px;
      transition: inherit;
    }

    &.c-btn--active {
      color: #2f50fd;
      border-color: #2f50fd;
      transition: all 0.2s ease-out;
      &:before {
        background: radial-gradient(
          circle at center,
          #2f50fd 0,
          #2f50fd 50%,
          #ffffff 55%,
          #ffffff 100%
        );
        border-color: #2f50fd;
        transition: inherit;
      }
    }
  }
}

.c-address-listview {
  max-height: 400px;
  overflow-y: auto;
}

.c-listview {
  padding-left: 0;
}

.c-listview__item {
  padding-left: $base-spacing-unit--tiny;
  margin-bottom: 0;
  padding-top: $base-spacing-unit--small;
  border-bottom: 1px solid theme-color(grey-200);
  &:before {
    display: none;
  }

  &:first-child {
    border-top: 1px solid theme-color(grey-200);
  }
}

.c-invoice {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 12px;
}

.c-listview__item--add {
  position: relative;
  padding-right: $base-spacing-unit--large * 2;

  &:after {
    content: '';
    position: absolute;
    background-image: url(/assets/icons/add_icon_black.svg);
    background-repeat: no-repeat;
    right: $base-spacing-unit--tiny;
    top: 41%;
    background-size: contain;
    height: $listview-edit-icon-size;
    width: $listview-edit-icon-size;
  }

  &:hover {
    cursor: pointer;
    background-color: theme-color(grey-300);
    transition: background-color 300ms ease-out;
  }

  transition: background-color 300ms ease-in;
}

.c-listview__item--editable {
  position: relative;
  padding-right: $base-spacing-unit--large * 2;

  &:before {
    content: 'edit';
    display: inline-block;
    position: absolute;
    right: $base-spacing-unit--large - 4px;
    top: 38%;
    left: auto;
    font-size: $base-font-size !important;
  }

  &.editable-no-text {
    &:before {
      content: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url(/assets/icons/back-arrow-black.svg);
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    right: $base-spacing-unit--tiny;
    top: 41%;
    background-size: contain;
    height: $listview-edit-icon-size - 8px;
    width: $listview-edit-icon-size;
  }

  &:hover {
    cursor: pointer;
    background-color: theme-color(grey-300);
    transition: background-color 300ms ease-out;
  }
  transition: background-color 300ms ease-in;

  &.c-listview__item--editable-newpage {
    padding-right: 0;
    &:before {
      content: '';
      position: absolute;
      background-image: url(/assets/icons/new-tab-icon.svg);
      background-repeat: no-repeat;
      right: $base-spacing-unit--tiny;
      top: 41%;
      background-size: contain;
      height: $listview-edit-icon-size;
      width: $listview-edit-icon-size;
    }
    &:after {
      display: none;
    }
  }
}

.c-listview__item--selectable {
  position: relative;
  padding-right: $base-spacing-unit--large * 2;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    right: $base-spacing-unit--large - 4px;
    top: 38%;
    left: auto;
    font-size: $base-font-size !important;
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url(/assets/icons/back-arrow-black.svg);
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    right: $base-spacing-unit--tiny;
    top: 41%;
    background-size: contain;
    height: $listview-edit-icon-size - 8px;
    width: $listview-edit-icon-size;
  }

  &:hover {
    cursor: pointer;
    background-color: theme-color(grey-300);
    transition: background-color 300ms ease-out;
  }
  transition: background-color 300ms ease-in;
}

.c-listview__heading {
  @extend p;
  font-weight: $font-weight-normal;
  margin-bottom: $base-spacing-unit--teeny;
  padding-left: 0;
}

/*
c-hero
*/
.c-hero {
  position: relative;

  img {
    width: 100%;
  }
}

.c-jumbotron {
  background: theme-color(grey-300);
  border-radius: $c-form-part-border-radius;
  padding: $base-spacing-unit--tiny;
  margin: $base-spacing-unit--tiny 0;

  button.c-btn.c-btn--primary {
    margin-bottom: 0;
  }

  h5 {
    margin: $base-spacing-unit--tiny 0;
  }
}

.c-btn-app-back {
  border: none;
  outline: none;
  position: relative;
  background-color: transparent;
  display: block;
  margin-bottom: $base-spacing-unit--small;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    position: relative;
    background-image: url(/assets/images/back-arrow.svg);
    background-repeat: no-repeat;
    transform: rotate(90deg);
    width: $base-spacing-unit--tiny;
    height: $base-spacing-unit--tiny;
    vertical-align: middle;
  }
}

.c-form-part__two-date-field-wrap {
  border-radius: 3px;
  position: relative;
  border: 1px solid #000000;
  max-width: 230px;
  margin-top: 8px;
  margin-right: 20px;

  &:after {
    content: '/';
    font-size: 17px;
    position: absolute;
    bottom: 16px;
    color: #57575c;
    opacity: 0.8;
    left: 50%;
  }

  .c-form-part__two-field.c-form-part__two-field-1 {
    padding-right: 5px;
    padding-left: 5px;
    max-width: 70px;
  }

  .c-form-part__two-field.c-form-part__two-field-2 {
    padding-right: 5px;
    padding-left: 10px;
    max-width: 70px;
  }

  .c-form-part__two-field {
    border: none;
    display: inline-block;
    background-color: transparent;
    margin: 0;
    text-align: center;
  }

  .c-form-part__entry--is-invalid & {
    border-color: #fb5e55;
  }
}

/*
Checkbox
 */

.c-checkbox {
  background: transparent;
  border: 1px solid lightgray;
  width: 19px;
  height: 19px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
  top: 3px;

  &:after {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background-image: url(/assets/icons/tick-blue.svg);
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    content: ' ';
    width: 10px;
    height: 10px;
    opacity: 0;
  }

  &.c-checkbox--checked::after {
    opacity: 1;
  }

  input[type='checkbox'] {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}

/*
Blog styling
 */
.c-blog-image {
  div,
  h2,
  p {
    display: none;
  }

  .hs-featured-image-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;

    img.hs-featured-image {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }
  }
}

.c-form-part__three-date-field-wrap,
.c-form-part__three-date-field-wrap {
  &:before,
  &:after {
    content: none;
  }
}
